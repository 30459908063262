import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import 'firebase/compat/firestore'

const app = firebase.initializeApp({
  apiKey: "AIzaSyAB4cwM0Ai1P6OeQ4Ud9MiFeYR9-zNEmg8",
  authDomain: "admin-dev-35cb8.firebaseapp.com",
  projectId: "admin-dev-35cb8",
  storageBucket: "admin-dev-35cb8.appspot.com",
  messagingSenderId: "1002233669165",
  appId: "1:1002233669165:web:165cd55df6ad421c52baa4"
})

export const auth = app.auth()
export default app