import React from 'react';
import { Link } from 'react-router-dom';

const SignsNav = () => {
  return (

    <div className="container">
      <div className="content-flow6">
          <h1>Edit the Inventory</h1>
      </div>
        <div className="content-flow2">
            <div className="inven-links">
              <Link to="/Components/Signs-Add" className="btnstyles">Add Signs:</Link>
              <p id="admin-inven">Select an image to be placed in the inventory and fill the fields.</p>
              <br></br>
            </div>
            <div className="inven-links">
              <Link to="/Components/Signs-Edit" className="btnstyles">Edit Current Signs:</Link>
              <p id="admin-inven">Select which images are visible to customers and view entire inventory.</p>
              <br></br>
            </div>
            <div className="inven-links">
              <Link to="/Components/Signs-Delete" className="btnstyles">Delete Signs:</Link>
              <p id="admin-inven">Select an image to delete, and must be confirmed.</p>
            </div>
        </div>
      </div>
  );
};

export default SignsNav;
