import React from 'react';
import AdminNavbar from '../../Components/AdminNavbar';

class AdminImageGallery extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      currentGallery : ["Data is being fetched"],
      gallery : ["Data is being fetched"],
      selected : ["Nothing is Selected"],
      addition : {image_name: 'name', gallery_image: null}
    };
  }


  RefreshGallery() {

    fetch(process.env.REACT_APP_API+ "/gallery", {
        credentials: 'include',
        method: 'GET'
      }).then((response) => {
        return response.json();
      }).then((data) => {
        this.setState({gallery : data});
      });

  }

  componentDidMount() {
    this.RefreshGallery();
  }

  EditGallery = (event, val) => {
    this.updateDisplayed(event,val);
  }

  updateDisplayed(val){

    const formData = new FormData();
    formData.append('image_id', val.target.name);
    if(val.target.value == 1)
          formData.append('displayed', true);
    else
          formData.append('displayed', false);

    fetch(process.env.REACT_APP_API + "/gallery/edit", {
        credentials: 'include',
        method: 'POST',
        body: formData,
      }).then(function(response) {
        return response.json();
      });

    this.RefreshGallery();
  }

  handleChange = (event) => {
    this.setState(prevState => ({
      addition: {
        ...prevState.addition,
        image_name: event.target.value
      }
    }));
  }

  fileChange = (event) => {
    this.setState(prevState => ({
      addition: {
        ...prevState.addition,
        gallery_image: event.target.files[0]
      }
    }));
  }

  handleSubmit = (event) => {
    event.preventDefault();

    const formData = new FormData();
    formData.append('image_name', this.state.addition.image_name);
    formData.append('gallery_image', this.state.addition.gallery_image);


    fetch(process.env.REACT_APP_API + "/gallery/new", {
        credentials: 'include',
        method: 'POST',
        body: formData,
      }).then(function(response) {
        console.log(response);
        this.RefreshGallery();
        return response.json();
      });

    this.RefreshGallery();
  }

  render(){

    return (
    <>
    <AdminNavbar />
      <div className="container">
        <div className="content-flow6">
          <h1>Gallery Image Selection</h1>
            <p id="gallinstruc">Currently displayed images in gallery. Hover over images for a delete option (X)</p>
            <table className="input">
                    <tr>
                      <th>Name</th>
                      <th width="400px">Image</th>
                    </tr>
                    {this.state.gallery.map((val, key) => {
                      // console.log(val);
                      if(val.displayed !== 1){
                        return (
                          <tr>
                            <td>{val.image_name}</td>
                            <td><img src={"../../uploads/" + val.gallery_image} height="200px"></img></td>
                            <td><button name={val.image_id} value={val.displayed} onClick={(event) => {this.EditGallery(event, val);}}>Remove Display</button></td>
                          </tr>
                        );
                      }
                    })}
            </table>

            <p id="notice">*Grid is not visible in the gallery, click to add images*</p>
            <div className="uploadTable">
                  <table>
                    <tr>
                      <th>Name</th>
                      <th  width="400px">Image</th>
                    </tr>
                    {this.state.gallery.map((val, key) => {
                     if(val.displayed == 1){
                      return (
                        <tr>
                          <td>{val.image_name}</td>
                          <td><img src={"../../uploads/" + val.gallery_image} height="200px"></img></td>
                          <td><button className="btnstyles" name={val.image_id} value={val.displayed} onClick={(event) => {this.EditGallery(event, val);}}>Display</button></td>
                        </tr>
                      );
                    }
                    })}
                  </table>
              </div>

              <h2 id="more-spacing">Add Gallery Image</h2>
              <div className="content-flow4">
                <form className="form-style-8" onSubmit={this.handleSubmit}>
                  <ul>
                    <label for="gallery_image">Select image: </label>
                    <li>
                      <input type="file" id="gallery_image" name="img" accept="image/*" onChange={this.fileChange}></input>
                    </li>
                    <label for="image_name">Name: </label>
                    <input type="text" id="image_name" name="sign_name" value={this.state.sign_name} onChange={this.handleChange} />
                    <br></br>
                    <li>
                      <input type="submit" value="Submit"></input>
                    </li>
                  </ul>
                </form>
              </div>

          </div>
      </div>
      </>
    );
  }
};

export default AdminImageGallery;
