/* eslint-disable array-callback-return */
import React, { useEffect, useState } from "react";

const delay = 2500;

const Home = () => {
  let slideIndex = 0;

  let [gallery, setGallery] = useState([]);
  let [index, setIndex] = useState(-1);
  const timeoutRef = React.useRef(null);

  function RefreshGallery() {
    fetch(process.env.REACT_APP_API + "/gallery", {
      method: "GET",
      credentials: "include",
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        data = data.filter(function( obj ) {
          return obj.displayed !== 1;
         });
        setGallery(data);
        console.log(data);
        setIndex(0);
      });
  }

  useEffect(() => {
    RefreshGallery();
  },[]);


  function resetTimeout() {
    if(timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  }

  useEffect(()=>{
    resetTimeout();
    timeoutRef.current = setTimeout(
      ()=>{
        setIndex((prevIndex)=>
          prevIndex === gallery.length -1 ? 0 : prevIndex +1
        );
      }
    , delay);
    return () => {resetTimeout();};
  }, [index]);

  return (
    <div className="container">
      <div className="slideshow">
        <div className="slideshowSlider" style={{transform: `translate3d(${-index*100}%, 0, 0)`}}>
          {gallery.map((val, key) => {
            return <div className="slide" key={key}>
                  <img
                    className="slideshowimg"
                    key={key}
                    alt="Featured Images"
                    src={"../../uploads/" + val.gallery_image}
                  ></img>
            </div>
               
           })}  
          
        </div>
        <div id="slideshowDots">
            {gallery.map((val, key) => {
              return <div key={key} className={`slideshowDot${index === key ? " active" : ""}`} onClick={()=>{setIndex(key);}}></div>
            })}
          </div>
      </div>

      <p id="intropgrph">
        Sassy Signs Greenville is a yard sign rental company in Greenville, SC.
        We deliver personalized yard signs to your home, business or school to
        help celebrate life's most memorable moments. We design, draw, cut,
        paint and personalize each sign for one purpose: to bring joy and smiles
        to all who see them and those you love. Unlike other sign companies in
        the area, our signs are made from sturdy, hand cut plywood, are hand
        painted and stand up to 5 feet tall. Our Sassy Signs make a big
        statement to give that extra wow factor. We also place solar lights, so
        your Sassy Signs will be enjoyed even at night!
      </p>
      <h1 id="center">How It Works</h1>
      <div className="wrapper">
        <div className="imgarea">
          <div>
            <a href="/about">
              {" "}
              <img
                className="singleimg"
                src=".../../../Images/pexels-pixabay-221027.jpg"
                alt="image1"
              ></img>
              <h1 id="center">Process</h1>{" "}
            </a>
          </div>
          <div id="center">
            <a href="/inventory">
              {" "}
              <img
                className="singleimg"
                src=".../../../Images/pexels-tofroscom-359757.jpg"
                alt="image1"
              ></img>
              <h1>Pricing</h1>
            </a>
          </div>
          <div id="center">
            <a href="/contact">
              <img
                className="singleimg"
                src=".../../../Images/pexels-mikhail-nilov-6609232.jpg"
                alt="image1"
              ></img>
              <h1>Purchase</h1>
            </a>
          </div>
        </div>
      </div>

      <div>
        <div className="gallery">
          {gallery.map((val, key) => {
            //official Gallery. all images uploaded by choice should be displayed
            return (
              <img
                className="photo"
                alt="test"
                src={"../../uploads/" + val.gallery_image}
              ></img>
            );
          })}
        </div>
      </div>

      <div>
        <h3 id="center">Testimonials</h3>
      </div>
      <div className="testimonialcontainer">
        <div className="testimonialcenter">
          <div className="testimonialtext">
            <h4>Mikaela W.</h4>
            <p id="stars">&#9733;&#9733;&#9733;&#9733;&#9733;</p>
            <p>
              Outstanding service and product! We’ve used a lot of different
              yard sign companies and Sassy Signs by far had the best product
              and the cutest display!! And fantastic customer service!!!
            </p>
          </div>
        </div>
        <div className="testimonialcenter">
          <div className="testimonialtext">
            <h4>Vickie G.</h4>
            <p id="stars">&#9733;&#9733;&#9733;&#9733;&#9733;</p>
            <p>
              5 stars+ Wonderful ladies to work with! Fantastic idea! Highly
              recommend
            </p>
          </div>
        </div>
        <div className="testimonialcenter">
          <div className="testimonialtext">
            <h4>Tina H.</h4>
            <p id="stars">&#9733;&#9733;&#9733;&#9733;&#9733;</p>
            <p>
              Outstanding customer service! The quality of the sign is simply
              amazing! I have found my sign company! I will use them again to
              help us celebrate special occasions!{" "}
            </p>
          </div>
        </div>
        <div className="testimonialcenter">
          <div className="testimonialtext">
            <h4>Tonya H.</h4>
            <p id="stars">&#9733;&#9733;&#9733;&#9733;&#9733;</p>
            <p>Highly recommend them. awesome signs for a great price!!</p>
          </div>
        </div>
        <div className="testimonialcenter">
          <div className="testimonialtext">
            <h4>Lanie C.</h4>
            <p id="stars">&#9733;&#9733;&#9733;&#9733;&#9733;</p>
            <p>Wonderful!</p>
          </div>
        </div>
      </div>

      <div></div>
      <div id="bkgrnd-elem4"></div>
    </div>
  );
};

export default Home;
