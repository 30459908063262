import React from 'react';
import { Link } from 'react-router-dom';

const AdminNavbar = () => {
  return (
      <div className="navcolor">
        <ul className="topnav">
          <li><Link to="/admin/">Admin Home</Link></li>
          <li><Link to="/admin/signs">Edit Inventory</Link></li>
          <li><Link to="/admin/imagegallery">Edit Gallery</Link></li>
        </ul>
      </div>
  );
};

export default AdminNavbar;
