import React from 'react';
import { Link } from 'react-router-dom';

const Navbar = () => {
  return (
      <div className="navbar">
        <ul className="topnav">
          <li><Link to="/">Home</Link></li>
          <li><Link to="/about">About</Link></li>
          <li><Link to="/inventory">Inventory</Link></li>
          <li><Link to="/contact">Contact</Link></li>
          <li id="navdiff"><Link to="/login">Login</Link></li>
        </ul>
      </div>
  );
};

export default Navbar;
