import React, { useEffect, useRef, useState } from 'react';
import emailjs from '@emailjs/browser';

export const ContactUs = () => {

 // const [selected, setSelected] = useState({data:[]});

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();
    //this.props.history.push('/contact')

/*    const data = new URLSearchParams();
    for (const pair of new FormData(form.current)) {
      data.append(pair[0], pair[1]);
    }
    console.log(data);
*/


   emailjs.sendForm('service_cw1ln1c', 'template_c5tawip', form.current, '5R4uxU6u0lWbXCFJ-')
      .then((result) => {
          console.log(result.text);
            alert("Email sent!");
            let formData = new FormData(form.current);

            fetch(process.env.REACT_APP_API + "/ContactUs", {
              credentials: 'include',
              //headers : {"content-type" : "application/x-www-form-urlencoded"},
              method: 'POST',
              body : formData
            }).then((response) => {

            });
                
      }, (error) => {
          console.log(error.text);
      });
    
  };
  

   /*useEffect(()=>{
    fetch('http://localhost:8000/ContactUs', {
      credentials: 'include',
      method: 'POST'
    }).then((response) => {
      return response.json();
    }).then((data) => {
      console.log(data);
      setSelected({data});

    });
    

    // GrabSigns(()=>{
    //   selected.data.map((val, index) => {return <span>{val.sign_name}, </span>});
    // });


   }, []);
   */




  return (
    <div className="container">
      


 
   <div className="container">

   <div className="TermsConditions">
     <h1>Terms and Conditions</h1>

      <ul>
       <li>Payment is required  upon booking to hold your reservation date.</li>
       <li>Venmo is preferred however cash or paypal are accepted.</li>
       <li>Please do not move or alter the sign display.</li>
       <li>Sassy Signs Greenville is not responsible or liable for any injury to any persons and/or property that may occur during the setup, duration or removal of the sign display.</li>
       <li>If you must cancel your order, please let us know as far in advance as possible. The full rental amount will be credited towards a future rental.</li>
       <li>The renter will be responsible for damage and/or theft to the Sassy Signs.</li>
     </ul>
</div>   
<form className="formContact" action="#" method="post"  ref={form} onSubmit={sendEmail} encType="multipart/form-data">
  <h2>Contact Us</h2>
  <p type="Name:"><input name="customer_name" placeholder="Write your name here.."></input></p>
  <p type="Email:"><input name="email" placeholder="Let us know how to contact you back.."></input></p>
  <p type="Phone Number:" ><input name="phoneNumber" placeholder="Let us know how to contact you back.."></input></p>
  <p type="Address:"><input name="address" placeholder="Let us know your address.."></input></p>
  <p type="Message:"><input className="messageInput" name="notes" placeholder="Tell us more about your event needs.."></input></p>
<div className="formButton">
  <button>Submit</button></div>
<div className="formLogo">
<img src=".../../../Images/Logo.png" alt="" />
</div>
</form>
   </div>
   
   </div>

 );
}

export default ContactUs;
