import React from 'react';

class SignsDelete extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      inventory : ["Data is being fetched"],
      selected : ["Nothing is Selected"],
    };
  }

  RefreshInventory() {

    console.log("refreshed");

    fetch(process.env.REACT_APP_API + "/inventory", {
        credentials: 'include',
        method: 'GET'
      }).then((response) => {
        return response.json();
      }).then((data) => {
        this.setState({inventory : data});
      });

  }

  SendDelete() {
    const formData = new FormData();
    formData.append('sign_id', this.state.selected.sign_id);

    fetch(process.env.REACT_APP_API +  "/inventory/delete", {
        credentials: 'include',
        method: 'POST',
        body: formData,
      }).then(function(response) {
        window.location = '/admin/signs';
        return response.json();
      });

    this.RefreshInventory();
  }

  componentDidMount() {
    this.RefreshInventory();
  }

  DeleteSign = (event, val) => {
    var data = val;
    console.log("hit 1");
    this.setState({selected : data});

  }

  static signList = this.state;

  render(){
    return (
      <>
      <div className="container">
        <div className="content-flow1">
          <div>
            <h2>Table of all the inventory</h2>
            <div>
              <table>
                <tr>
                  <th>Name</th>
                  <th>Category</th>
                  <th>Quantity</th>
                  <th>Image</th>
                </tr>
                {this.state.inventory.map((val, key) => {

                  return (
                    <tr>
                      <td>{val.sign_name}</td>
                      <td>{val.category_name}</td>
                      <td>{val.quantity}</td>
                      <td><img src={"../../uploads/" + val.sign_image} alt="" height="200px"></img></td>
                      <td><button value={val.sign_id} onClick={(event) => {this.DeleteSign(event, val);}}>Delete</button></td>
                    </tr>
                  );
                })}
              </table>
            </div>
              <div>
              <h3>Confirm Deletion Below:</h3>
              <table>
                <tr>
                  <th>Name</th>
                  <th>Category</th>
                  <th>Quantity</th>
                  <th>Image</th>
                </tr>
                    <tr>
                      <td>{this.state.selected.sign_name}</td>
                      <td>{this.state.selected.category_name}</td>
                      <td>{this.state.selected.quantity}</td>
                      <td><img src={"../../uploads/" + this.state.selected.sign_image} alt=""></img></td>
                    </tr>
              </table>
              <p>Are you sure you want to delete this? <button onClick={this.SendDelete.bind(this)}>Confirm Delete</button></p>
              <div id="page-break4">
              </div>
            </div>
        </div>
        </div>
    </div>
    </>

    );
  }
};

export default SignsDelete;
