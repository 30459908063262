import React from 'react';

const Header = () => {
  return (
      <div className="header">
    
      </div>
  );
};

export default Header;