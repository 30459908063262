import React from 'react';

class AboutUs extends React.Component {
  // constructor(props) {
  //   super(props);
  //   this.state = {
  //     gallery : ["Data is being fetched"]
  //   };
  // }

  // RefreshGallery() {

  //   fetch('http://localhost:8000/gallery', {
  //       method: 'GET'
  //     }).then((response) => {
  //       return response.json();
  //     }).then((data) => {
  //       this.setState({gallery : data});
  //     });
  // }

  // componentDidMount() {
  //   this.RefreshGallery();
  // }

  render(){
    return (
     
      <div id="content"><br></br>
      <h1>Who We Are</h1>
  
       
        <div class="card">
            <div class="imgAbout">
            <img src=".../../../Images/aboutus.jpg" alt="" />
            </div>
            <div class="box">
              <h3>SASSY SIGNS</h3>
                  <p>
                    In August of 2020, Sassy Signs was founded by Harper Steele and Anna Kenan,
                    two former neighbors and close friends with a passion for creativity and entrepreneurship. 
                    Their goal was to utilize their talents during the pandemic, and what better way to spread 
                    joy than through their eye-catching, oversized, and handmade wooden signs? They set out to
                    create a unique product that stood out from the rest, offering customers a special way to 
                    surprise their loved ones. 
                    </p>
            </div>
        </div><br></br>
     
        <h1>Meet The Team</h1>
   
   
   
        <div class="card">
          
            <div class="boxAnna">
              <h3>ANNA KENAN</h3>
        <p>
        Anna Kenan has been a resident of Greenville for more than twenty years now!
        Alongside her husband and two children - a son and daughter, she manages
        a vinyl monogramming business and also works part-time at Good to Go
        Greenville. During her free time, Anna finds pleasure in traveling and visiting the beach.

      </p>
            </div>
            <div class="imgAnna">
            <img src=".../../../Images/anna.jpeg" alt="" />
            </div>
            
        </div>
        
        <div class="card">
            <div class="imgHarper">
            <img src=".../../../Images/harper.jpeg" alt=""/>
            </div>
            <div class="box">
              <h3>HARPER STEELE</h3>
        <p>
        For more than two decades, Harper Steele has resided in Greenville. She shares two daughters with 
        her husband of over twenty years. Harper divides her time between serving as a companion for seniors
        and running her interior decorating business. In her leisure, she relishes taking long strolls with 
        her furry companions and the occasional game of pickleball.
</p>
            </div>
        </div><br></br>
        

    </div>
    );
  }
};

export default AboutUs;
