import React from 'react';

const HeroImg = () => {
  return (
      <div className="heroimg">
    
      </div>
  );
};

export default HeroImg;