import React from 'react';
import SignsAdd from '../../Components/Signs-Add';
import SignsEdit from '../../Components/Signs-Edit';
import SignsDelete from '../../Components/Signs-Delete';
import SignsNav from '../../Components/SignsNav';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import AdminNavbar from '../../Components/AdminNavbar';
import Home from '../';
import AboutUs from '../AboutUs';
import Inventory from '../Inventory';

const AdminSigns = () => {


  return (
    <>
    <AdminNavbar />

    <Router>
      <SignsNav />
      <Switch>
        <Route path='/Components/Signs-Add' exact component={SignsAdd} />
        <Route path='/Components/Signs-Edit' component={SignsEdit} />
        <Route path='/Components/Signs-Delete' component={SignsDelete} />
      </Switch>
    </Router>
    
    <div>
  <SignsAdd />
       <SignsEdit />
       <SignsDelete />
       <Switch>
         <Route path='/' exact component={Home} />
         <Route path='/about' component={AboutUs} />
         <Route path='/inventory' component={Inventory} />
       </Switch>
    </div>
  </>
  );
};

export default AdminSigns;
