import React, {useRef }from 'react';
import emailjs from '@emailjs/browser';

  
  
    const Footer = () => {
      const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();
    //this.props.history.push('/contact')

/*    const data = new URLSearchParams();
    for (const pair of new FormData(form.current)) {
      data.append(pair[0], pair[1]);
    }
    console.log(data);
*/


   emailjs.sendForm('service_cw1ln1c', 'template_r6uv5es', form.current, '5R4uxU6u0lWbXCFJ-')
      .then((result) => {
          console.log(result.text);
            alert("Email sent!");
            window.location = '/';

            let formData = new FormData(form.current);

            fetch(process.env.REACT_APP_API + "/ContactUs", {
              credentials: 'include',
              //headers : {"content-type" : "application/x-www-form-urlencoded"},
              method: 'POST',
              body : formData
            }).then((response) => {

            });
                
      }, (error) => {
          console.log(error.text);
      });
    };

     return (
    <div className="main-content">
    <div className="image-background"></div>
    <div className="footcontent">
      <div className="social-media">
        <div className="TEXTABOV">
          <h3 className="footertitles">Social Media</h3>
          <p>See Our Signs in Action</p>
          <a href="https://www.facebook.com/sassysignsgreenville/" className="fa fa-facebook"><img  src=".../../../Images/facebook.png" alt=""></img></a>
          <a href="https://www.instagram.com/sassysignsgreenville/" className="fa fa-instagram"><img src='.../../../Images/icons8-instagram-50.png' alt=""></img></a>
        </div>
      </div>
      <div className="email-signup">
        <h3 className="footertitles">Subscribe to our newsletter</h3>
        <form action="mailto:sassysignsgreenville@gmail.com" method="post"  ref={form} onSubmit={sendEmail} encType="multipart/form-data">
          <input type="email" name="email" placeholder="Enter your email address" required></input>
          <button type="submit">Subscribe</button>
        </form>
      </div>
    </div>
  </div>
  );

  };

export default Footer;
