/* eslint-disable array-callback-return */
/* eslint-disable eqeqeq */
import React from 'react';

class Inventory extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      inventory : ["Data is being fetched"]
    };
  }

  RefreshInventory() {

    //console.log("refreshed");
    fetch(process.env.REACT_APP_API + "/inventory", {
        credentials: 'include',
        method: 'GET'
      }).then((response) => {
        return response.json();
      }).then((data) => {
        this.setState({inventory : data});

      });

      /*fetch('http://localhost:8000/getCart', {
        credentials: 'include',
        method: 'GET'
      }).then((response) => {
        return response.json();
      }).then((data) => {
        this.setState({selected : data});
        //console.log(data);
      });
      */

  }

 /* getCart() {
    console.log("cart refreshed");

    fetch('http://localhost:8000/getCart', {                    *****DO NOT WANT CART ******
        credentials: 'include',
        method: 'GET'
      }).then((response) => {
        return response.json();
      }).then((data) => {
        this.setState({selected : data});
        //console.log(data);
      });
  }

  addSignToCart(event, val) {
    event.preventDefault();

    // let id = val.sign_id;
    // console.log("add to cart sign id: ", id);
    const formData = new FormData();
    formData.append('sign_id', val.sign_id);
    formData.append('sign_name', val.sign_name);
    formData.append('sign_image', val.sign_image);
    console.log(formData);


    fetch('http://localhost:8000/addCart', {
        credentials: 'include',
        method: 'POST',
        body: formData,
      }).then((response) => {
        console.log(response);
        this.getCart();
        //return response.json();
      });
  }
  */

  componentDidMount() {
    this.RefreshInventory();
  }

  render(){
    return (
      <div className="container">
        <div className="content-flow5">
          <h1>Pricing Details</h1>
          <div id='inventorydiv'>
          <ul id='details'>
            <li>Your <i>first Sassy Sign is $65</i> and comes with vinyl personalization of the event. Each <i>additional sign is $10</i>. We install your Sassy Signs the evening before the event and pick them up the day after which allows you to enjoy your signs for 40-48 hours.</li>
            <li>Pending availability, <i>rental sign extension is $10 per day.</i></li>
            <li>Birth announcement Sassy Signs are $85. These stay up for 5 days. Choose from larger-than-life, pink or blue rattles, or baby carriages. Included is a keepsake wooden medallion with the baby's birth information. Don't forget big brother or sister! Add a star with their name for only $10.</li>
            <li>Delivery is free within a 15 mile radius of I-85/385 in Greenville. Please contact us for delivery fees outside of this area, custom sign requests, or general information.</li>
            <li> <i>Please hover over images to scroll and view all images in given categories</i></li>
           </ul>
           <img id='inventoryimg' src=".../../../Images/H1.jpg" alt= " "></img>
           </div>
            <h1>Inventory</h1>
        <ul className="iventorytopnav">
            <li className='inventorynav'><a href='#birthday'>Birthday‎ ‎ ‎ </a></li>
           <li className='inventorynav'> <a href='#grad'>Graduation‎  ‎ ‎ </a> </li>
            <li className='inventorynav'><a href='#numbers'>Numbers‎  ‎ ‎ </a></li>
            <li className='inventorynav'><a href='#babyannouncement'>Baby Announcements ‎ ‎ ‎ </a></li>
            <li className='inventorynav'><a href='#holiday'>Holidays‎  ‎ ‎ </a></li>
            <li className='inventorynav'><a href='#addon'>Add-Ons‎  ‎ ‎ </a></li>
            <li className='inventorynav'><a href='#sports'>Sports‎ ‎ ‎ </a></li>
            <li className='inventorynav' id='navdiff'><a href='#anniversary'>Anniversary‎ ‎ ‎ </a></li>
        </ul>
        <div className="content-flow5">
            <h3 id='birthday'>Birthday</h3>
            <p id='pgarrow'>scroll right to see more</p>
            <p id='arrows'>  &harr;</p>
        </div>
        <div className='inventoryimgcontainer'>
        {this.state.inventory.map((val, key) => {
        if(val.category_id == 1){
            return (
              <div id='lines'>
              <div className='imgpaddinginventory'>
                <img className = "Birthday" alt="birthday" src = {"../../uploads/" + val.sign_image }></img>
             </div>
             <p className='signName'> {val.sign_name} </p>
             </div>
            );
           }
            else{
              return;
            }
          })
        }
        </div>
      
        <div className="content-flow5">
          <h3 id='grad'>Graduation</h3>
          <p id='pgarrow'>scroll right to see more</p>
            <p id='arrows'>  &harr;</p>

        </div>
        <div className='inventoryimgcontainer'>
        {this.state.inventory.map((val, key) => {
        if(val.category_id == 2){
            return (
              <div id='lines'>
              <div className='imgpaddinginventory'>
                <img className = "Graduation" alt="graduation" src = {"../../uploads/" + val.sign_image }></img>
              </div>
              <p className='signName'> {val.sign_name} </p>
              </div>
            );
           }
            else{
              return;
            }
          })
        }
        </div>
        <div className="content-flow5">
          <h3 id='numbers'>Numbers</h3>
          <p id='pgarrow'>scroll right to see more</p>
            <p id='arrows'>  &harr;</p>

        </div>
        <div className='inventoryimgcontainer'>
        {this.state.inventory.map((val, key) => {
        if(val.category_id == 3){
            return (
              <div id='lines'>
                <div className='imgpaddinginventory'>
                <img className = "Numbers" alt="numbers" src = {"../../uploads/" + val.sign_image }></img>
              </div>
              <p className='signName'> {val.sign_name} </p>
              </div>
            );
           }
            else{
              return;
            }
          })
        }
        </div>
        <div className="content-flow5">
          <h3 id='babyannouncement'>Baby Announcements</h3>
          <p id='pgarrow'>scroll right to see more</p>
            <p id='arrows'>  &harr;</p>

        </div>
        <div className='inventoryimgcontainer'>
        {this.state.inventory.map((val, key) => {
        if(val.category_id == 4){
            return (
              <div id='lines'>
                <div className='imgpaddinginventory'>
                <img className = "Baby" alt="baby" src = {"../../uploads/" + val.sign_image }></img>
              </div>
              <p className='signName'> {val.sign_name} </p>
                </div>
            );
           }
            else{
              return;
            }
          })
        }
        </div>
        
          <div className="content-flow5">
          <h3 id='holiday'>Holidays</h3>
          <p id='pgarrow'>scroll right to see more</p>
            <p id='arrows'>  &harr;</p>

        </div>
        <div className='inventoryimgcontainer'>
        {this.state.inventory.map((val, key) => {
        if(val.category_id == 5){
            return (
              <div id='lines'>
              <div className='imgpaddinginventory'>
                <img className = "Holidays" alt="holidays" src = {"../../uploads/" + val.sign_image }></img>
              </div>
              <p className='signName'> {val.sign_name} </p>
              </div>
            );
           }
            else{
              return;
            }
          })
        }
        </div>
        
        <div className="content-flow5">
          <h3 id='addon'>Add-Ons</h3>
          <p id='pgarrow'>scroll right to see more</p>
            <p id='arrows'>  &harr;</p>

        </div>
        <div className='inventoryimgcontainer'>
        {this.state.inventory.map((val, key) => {
        if(val.category_id == 6){
            return (
              <div id='lines'>
              <div className='imgpaddinginventory'>
                <img className = "Add-Ons" alt="add-ons" src = {"../../uploads/" + val.sign_image }></img>
              </div>
              <p className='signName'> {val.sign_name} </p>
              </div>
            );
           }
            else{
              return;
            }
          })
        }
        </div>
        <div className="content-flow5">
          <h3 id='sports'>Sports</h3>
          <p id='pgarrow'>scroll right to see more</p>
            <p id='arrows'>  &harr;</p>

        </div>
        <div className='inventoryimgcontainer'>

        {this.state.inventory.map((val, key) => {
        if(val.category_id == 7){
            return (
              <div id='lines'>
              <div className='imgpaddinginventory'>
                <img className = "Sports" alt="sports" src = {"../../uploads/" + val.sign_image }></img>
              </div>
              <p className='signName'> {val.sign_name} </p>
              </div>
            );
           }
            else{
              return;
            }
          })
        }
        </div>
        <div className="content-flow5">
          <h3 id='anniversary'>Anniversary</h3>
          <p id='pgarrow'>scroll right to see more</p>
            <p id='arrows'>  &harr;</p>

        </div>
        <div className='inventoryimgcontainer'>
          {this.state.inventory.map((val, key) => {
        if(val.category_id == 8){
            return (
              <div id='lines'>
              <div className='imgpaddinginventory'>
                <img className = "Anniversary" alt="anniversary" src = {"../../uploads/" + val.sign_image }></img>
              </div>
              <p class='signName'> {val.sign_name} </p>
              </div>
            );
           }
            else{
              return;
            }
          })
        }
        </div>



          
        </div>

      <a id='center' href='/contact'><button id='inventorybutt'> Contact Us </button></a>
        </div>
    );
  }
};

export default Inventory;
